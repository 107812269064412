<template>
    <Transition>
        <template v-if="!voteResult">
            <div class="global-popup section-popup">
                <div class="popup-bg" v-on:click="$emit('close-popupVote'),body.removeAttribute('style')"></div>
                <div class="popup-container rounded-md-4">
                    <div class="popup-close popout"  v-on:click="$emit('close-popupVote'),body.removeAttribute('style')" data-popup="popup-content"><img src="@/assets/img/skin/icon-close.svg" alt=""></div>
                    <div class="box-popup">
                        <div class="popup-body ">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="pop-cont-overlap">
                                        <div class="img-overlap">
                                          
                                           <img v-if="location_data_array.imageCover != ''" :src="require(`../../assets/img/77-attractions/${replaceExtension(locationTicker[0].imageCover)}`)" alt="" class="fw">
    
                                        </div>
                                        <div class="fw text-center ">
                                            <p class="txt-w-l gray-lighter">คุณต้องการโหวตแหล่งท่องเที่ยวสุดว้าว <span style="display:inline-block;" class="primary">{{ location_data_array.location_region_th }}</span> ให้กับ</p>
                                            <h3  class="txt-w-xl primary" v-html="location_data_array.location_name"></h3>
                                            <p class="txt-w-l gray-lighter">จ.{{ location_data_array.location_province_th }}</p>
                                             <a href="javascript:void(0)" class="mt-4 btn btn-ctr-primary rounded-pill px-4" @click="voteAPI();"> <span>ยืนยันการโหวต</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
                <div class="global-popup section-popup voteResult">
                    <div class="popup-bg" v-on:click="$emit('close-popup'),body.removeAttribute('style')"></div>
                    <div class="popup-container rounded-md-4">
                        <div class="popup-close popout"  v-on:click="$emit('close-popup'),body.removeAttribute('style')" data-popup="popup-content"><img src="@/assets/img/skin/icon-close.svg" alt=""></div>
                        <div class="box-popup">
                            <div class="popup-body ">
                                <div class="row justify-content-center">
                                    <div class="col-10">
                                        <div class="pop-cont-overlap">
                                            <div class="img-overlap">
                                                <img src="@/assets/img/element/pop-heart.png" alt="" class="fw">
                                            </div>
                                            <div v-if="userAssets.profile.credit_vote > 0" class="fw text-center">
                                                <h3 class="txt-w-lg primary">ขอบคุณสำหรับการโหวต</h3>
                                                <p class="txt-w-l">คุณยังเหลือสิทธิ์ในการโหวตภาคอื่นๆ อีก</p>
                                                <h2  class="txt-w-xl credit-result"><span>{{ userAssets.profile.credit_vote }}</span> สิทธิ์</h2>
                                                
                                                <div class="group-share mt-4 ">
                                                    <a v-if="pageCheck == false" href="javascript:void(0)"  v-on:click="$emit('close-popup')" @click="switchRegion" class="btn btn-ctr-primary  rounded-pill px-4" ><span>โหวตภาคอื่นๆ</span></a>
                                                    <router-link v-else to="/attractions" v-on:click="$emit('close-popup')" class="btn btn-ctr-primary  rounded-pill px-4">โหวตภาคอื่นๆ</router-link>
                                                    <div class="box-share">
                                                        <p class="me-2">แชร์</p>
                                                        <a href="javascript:void(0);" @click="() => { openFacebookShare(); handleClick('share-success', location_data_array.location_name); }">
                                                    <img class="svg" src="@/assets/img/skin/icon-f-blue.svg" alt="">
                                                    </a>


                                                    </div>
                                                </div>
                                                
                                            </div>
    
                                            <div v-else class="fw text-center">
                                                <h3 class="txt-w-lg primary">ขอบคุณสำหรับการโหวต</h3>
                                                <p class="txt-w-l">สิทธิ์ในการโหวตของคุณหมดแล้ว</p>
                                                    <div class="group-share mt-4 ">
                                                        <div class="box-share">
                                                            <p class="me-2">แชร์</p>
                                                            <a href="javascript:void(0);" @click="() => { openFacebookShare(); handleClick('share-success', location_data_array.location_name); }">
                                                                <img class="svg" src="@/assets/img/skin/icon-f-blue.svg" alt="">
                                                            </a>
                                                            </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
        </template>
    </Transition>
    </template>
    
    <script>
    
    /* eslint-disable */
    import { setFunction } from '@/global'
    import axios from 'axios';
    import Cookies from 'js-cookie';
    import VueSocials from 'vue-socials'
    export default {
        data() {
            return {
                dataToken: Cookies.get('token'),
                userProfile:'',
                userHistory: '',
                voteResult: false,
                isloade:false,
                body:  document.querySelector('body'),
                pageCheck: false,
                userAssets:  sessionStorage.getItem('USER_INFO'),
                login_Status: Cookies.get('login_status'),
                currentUrl: window.location.href,
                tokenSatus: Cookies.get('token'),
                profileData: '',
                historyData: '',
                location_ID_send:'',
                location_data_array: []
            }
        },
        components: {
            VueSocials
        },
        props: ['locationTicker','userId'],
        mounted(){
            if (this.$route.name =='home') {
                this.pageCheck = true;
            }
            setTimeout(() => {
                setFunction();
                this.$root.svg();
            }, 100);
            this.body.style.overflow = 'hidden';
    
            if (this.login_Status == 1 ) {
                if (this.userAssets != null) {
                    let decodedDataString = atob(this.userAssets);
                    let decodedArray = JSON.parse(decodedDataString);
    
                    this.profileData = decodedArray.profile;
                    this.historyData = decodedArray.history;
    
                    this.loginStatus = true;
                    this.current_week = 'week_' + decodedArray.history.current_week;
                    window.addEventListener("storage", (event) => {
                        if (sessionStorage.getItem('USER_INFO') !== null|| sessionStorage.getItem('USER_INFO') !== undefined) {
                            let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                            let decodedArray = JSON.parse(decodedDataString);
                            this.profileData = decodedArray.profile;
                            this.historyData = decodedArray.history;
                            this.current_week = 'week_' + decodedArray.history.current_week;
                        }
                    });
                     window.addEventListener("change", (event)  => {
                        if (Cookies.get('login_status') == undefined) {
                            sessionStorage.removeItem('USER_INFO');
                            let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                            let decodedArray = JSON.parse(decodedDataString);
                            this.profileData = decodedArray.profile;
                            this.historyData = decodedArray.history;
                            this.current_week = 'week_' + decodedArray.history.current_week;
                            // // console.log('cookieStore change removeItem');
                        }else{
                            let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                            let decodedArray = JSON.parse(decodedDataString);
                            this.profileData = decodedArray.profile;
                            this.historyData = decodedArray.history;
                            this.current_week = 'week_' + decodedArray.history.current_week;
                        }
                    });
    
                }
            }else{
                sessionStorage.removeItem('USER_INFO');
            }
    
    
            this.location_data_array =  this.locationTicker[0];
            this.location_ID_send = this.locationTicker[0].location_ID;
            window.addEventListener('storage', this.handleStorageUpdate);
        },
        beforeUnmount() {
            window.removeEventListener('storage', this.handleStorageUpdate);
        },
        methods: {
            replaceExtension(imageUrl) {
                return imageUrl.replace(/\.jpg$/, '.webp');
            },
            handleClick(name,value) {
                this.clickTracking(name,value);
                // Additional logic for handling the button click
            },
            updateData() {
                if (sessionStorage.getItem('USER_INFO')) {
                    const decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                    const decodedArray = JSON.parse(decodedDataString);
                    this.profileData = decodedArray.profile;
                    this.historyData = decodedArray.history;
                    this.current_week = 'week_' + decodedArray.history.current_week;
                }
            },
            openFacebookShare() {
                const facebookShareUrl = `https://www.unseennewchapters.com/sharefb.php?slug=${this.locationTicker[0].location_ID}`;
                // Open the Facebook sharing dialog in the web version
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(facebookShareUrl)}`,
                    'facebook-share-dialog',
                    'width=800,height=600'
                );
                
                return false;
            },

            
            switchRegion() {
                this.body.removeAttribute('style');
                this.$emit('button-clicked');
            },
    
            async voteAPI() {
                this.$root.svg();
                this.$emit('vote-success', true);
                this.isloade = true
               if (this.userAssets == null || this.userAssets == '') {
                    window.location.reload();    
               }else{
                
                    try {
                        
                        const formData = new FormData();
                        formData.append('location_id', this.location_ID_send);
                        formData.append('member_id', this.userId);
                        formData.append('token', this.dataToken );
    
                        const response = await axios.post('https://www.unseennewchapters.com/nx/API/vote', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${this.dataToken}`
                            }
                        });
                        if (response.data !== '') {
                            // // console.log(response.data.rs);
                            if (response.data.rs == true) {
                                if ((this.userAssets != null || this.userAssets != undefined) && this.login_Status == 1) {
                                    let decodedDataString =  atob(response.data.userAssets);
                                    let decodedArray = JSON.parse(decodedDataString);
                                    this.userProfile = decodedArray.profile;
                                    this.userHistory = decodedArray.history;
                                    const dataResponse = response.data.userAssets;
                                    // Cookies.set('login_status', 1);
                                    sessionStorage.setItem('USER_INFO', dataResponse);
                                    this.userAssets = decodedArray;
                                    this.voteResult = true;
                                    this.$root.updateData();
                                    this.$emit('after-vote');
                                    // if (this.locationTicker[0].location_region_en != undefined) {
                                    //     this.$emit('after-voteHome', this.locationTicker[0].location_region_en,this.locationTicker[0].location_ID,location_region_en );
                                    // }
                                    
                                    // this.$root.getProfile();
                                }
                                let url = new URL(window.location.href);
                                url.searchParams.delete('slug');
                                window.history.replaceState(null, null, url.href);
                                // this.getProfile();
                                setTimeout(() => {
                                    this.$emit('vote-success', false);
                                    this.$emit('connect-api', false); 
                                }, 100);
                            }else{
                                if ( response.data.type  == 'session_timeout') {
                                    setTimeout(() => {
                                        this.$emit('vote-success', false);
                                        this.$emit('session-timeout', true); 
                                        // // console.log('voteAPI false');
                                    }, 100);
                                }else{
                                    setTimeout(() => {
                                        this.$emit('vote-success', false);
                                        this.$emit('connect-api', true); 
                                        // // console.log('voteAPI false');
                                    }, 100);
                                }
                            }
                          
                        }
                       
                        
                    } catch (error) {
                        // // console.log(error);
                        setTimeout(() => {
                            this.$emit('vote-success', false);
                            this.$emit('connect-api', true); 
                        }, 100);
                        
                    }
               }
               
            },
    
            async getProfile() {
                try { 
                    const formData = new FormData();
                    formData.append('member_id', this.profileData.id);
                    formData.append('token', this.dataToken );
                    
    
                    const response = await axios.post('https://www.unseennewchapters.com/nx/API/getProfile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.dataToken}`
                        }
                    });
                    if (response.data != '') {
                        if (response.data.rs == true) {
                            if ((this.userAssets != null || this.userAssets != undefined) && this.login_Status == 1) {
                                let decodedDataString =  atob(response.data.userAssets);
                                let decodedArray = JSON.parse(decodedDataString);
                                this.userProfile = decodedArray.profile;
                                this.userHistory = decodedArray.history;
                                const dataResponse = response.data.userAssets;
                                // Cookies.set('login_status', 1);
                                sessionStorage.setItem('USER_INFO', dataResponse);
                                this.userAssets = decodedArray;
                                // // console.log('update profile');
                            }
                        }
                    }
                    
                    
                } catch (error) {
                    // // console.log(error);
                }
               
            },
        }
    
    }
    </script>
    
    
    <style scoped>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .popup-container { width: 500px;overflow: visible;border-radius: 20px;}
    .pop-cont-overlap{width: 100%;display: block;float: left;}
    .img-overlap{position: relative;top: -100px;width: 300px;padding: 5px;margin: 0 auto;display: block;margin-bottom: -70px; }
    .section-popup:not(.voteResult) .img-overlap{ background-color: #fff; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);border-radius: 15px;}
    .img-overlap img{border-radius: 10px;    float: none;display: block;}
    
    .voteResult .img-overlap{
        top: -120px;
        margin: 0 auto;
        margin-bottom: -120px;
    }
    .section-popup{color:#7C7C7C;}
    .popup-close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #EC268F;
        color: #fff;
    }
    .section-popup{z-index: 1001;}
    .credit-result{display: flex;justify-content: center;align-items: center;color: #7C7C7C;margin-top: 10px;}
    .credit-result span{background: linear-gradient(257.32deg, #FBAE16 -32.14%, #EC268F 110.73%);width: 60px;height: 60px;display: flex;align-items: center;justify-content: center;border-radius: 100px;color: #fff;margin-right: 10px;}
    
    .group-share {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .box-share {
        display: flex;
        align-items: center;
        margin: 0 40px;
    }
    .box-share a{cursor: pointer;}
    .box-share a svg{fill: #4267B2;transition: all ease-in .2s;}
    .box-share a:hover svg{fill:#DBDBDB ; opacity:0.5}
    
    @media (max-width: 767.98px){
        .popup-container {
            width: 90vw;
            overflow: visible;
            /* top: auto;
            bottom: 0;
            transform: translate(-50%,0);
            border-radius: 10px 10px 0 0; */
        }
        .img-overlap{width: 190px;}
        .credit-result {
        font-size: 1.3em;
        }
        .credit-result span {
            width: 40px;
            height: 40px;
        }
        .voteResult .img-overlap{
        top: -110px;
        margin: 0 auto;
        margin-bottom: -120px;
        width: 230px;
    }
    }
    </style>
    
import axios from 'axios';

const state = {
  posts: []
};

const getters = {
  allPosts: (state) => state.posts
};

const actions = {
  async fetchPosts({ commit }) {
    try {
      const response = await axios.get('https://www.unseennewchapters.com/nx/API/get_locations');
      commit('setPosts', response.data.data);
    } catch (error) {
      // // console.log(error);
    }
  }
};

const mutations = {
  setPosts: (state, posts) => (state.posts = posts)
};

export default {
  state,
  getters,
  actions,
  mutations
};
